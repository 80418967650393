<template>
  <div class="w-full h-full px-5 pt-5">
    <div class="flex flex-col">
      <div class="flex justify-start items-center gap-8">
        <h1 class="text-xl text-left font-extrabold">
          Documents
        </h1>
        <breadcrumb :items="breadcrumbs" />
      </div>

      <div v-if="user" class="w-full flex flex-row justify-between gap-3 mt-6 mb-3">
        <Card class="card flex flex-row justify-start items-center gap-3 p-3">
            <div>
                <icon icon-name="user" size="xsm" />
            </div>
            <div>
                <p class="text-base font-semibold text-romanSilver">Employee Name/Designation</p>
                <p class="text-sm text-jet font-semibold">{{ user.fname && user.lname ? user.fname + ' ' + user.lname : "-"}}</p>
            </div>
        </Card>   
        <Card class="card flex flex-row justify-start items-center gap-3 p-3">
            <div>
                <icon icon-name="document" size="xsm" />
            </div>
            <div>
                <p class="text-base font-semibold text-romanSilver">Function</p>
                <p class="text-sm text-jet font-semibold">{{ user.orgFunction && user.orgFunction.name ? user.orgFunction.name : "-"  }}</p>
            </div>
        </Card>  
        <Card class="card flex flex-row justify-start items-center gap-3 p-3">
            <div>
                <icon icon-name="user" size="xsm" />
            </div>
            <div>
                <p class="text-base font-semibold text-romanSilver">Line Manager</p>
                <p class="text-sm text-jet font-semibold">
                  {{ user.userReportingTo && user.userReportingTo.name ? user.userReportingTo.name.fname  + ' ' +  user.userReportingTo.name.lname : "-" }}
                </p>
            </div>
        </Card>  
      </div>

      <div class="w-full flex flex-row justify-between gap-3 mb-6">
        <Card class="card flex flex-row justify-start items-center gap-3 p-3">
            <div>
              <img
                src="@/assets/images/files-multi.png"
                style="width: 24px; height: 24px"
              />
            </div>
            <div>
                <p class="text-base font-semibold text-romanSilver">All Files</p>
                <p class="text-sm text-jet font-semibold uppercase">
                  {{ `${numberOfFolders} folders, ${numberOfFiles} files, ${totalSize}` }}
                </p>
            </div>
        </Card>   
        <Card class="card flex flex-row justify-start items-center gap-3 p-3">
            <div>
              <img
                src="@/assets/images/file-upload.png"
                style="width: 24px; height: 24px"
              />
            </div>
            <div>
                <p class="text-base font-semibold text-romanSilver">Last Upload</p>
                <p class="text-sm text-jet font-semibold">Proof of Identity</p>
                <p class="text-10 text-flame font-normal">10:00am | 12th August, 2024.</p>
            </div>
        </Card>  
        <Card class="card flex flex-row justify-start items-center gap-3 p-3">
            <div>
              <img
                src="@/assets/images/user-status.png"
                style="width: 24px; height: 24px"
              />
            </div>
            <div>
                <p class="text-base font-semibold text-romanSilver">Current Status</p>
                <p class="text-sm text-jet font-semibold uppercase">
                  On Leave (OR Active)
                </p>
            </div>
        </Card>  
      </div>

      <Tab
        :tabs="tabs"
        border
        :active-tab="currentTab"
        @currentTab="currentTab = $event"
      />
      <div class="h-full">
        <div v-if="currentTab === 'All Files'">
          <loader v-if="isloading" size="xxs" :loader-image="false" />
          <div v-else>
            <Card class="p-5">
              <template v-slot:footer>
                <card-footer
                    @reload="reload()"
                    @sortType="handleSort($event)"
                    @option="handleOptionSelect($event)"
                    @searchResult="handleSearch($event)"
                    @actionModal="viewFilterTable($event)"
                    search-placeholder="Search"
                    :show-sort-filter="false"
                />
              </template>
            </Card>

            <div class="line" v-if="files.length > 0"/> 

            <sTable
              :headers="filesHeader"
              :items="files"
              style="width: 100%"
              :loading="isloading"
              aria-label="employee table"
              class="w-full"
              id="printMe"
              :pagination-list="allFilesMeta"
              @page="handlePage($event)"
              @itemsPerPage="handleItemsPerPage($event)"
              page-sync
              :has-number="false"
              :has-checkbox="true"
              v-if="files.length > 0"
            >
              <template v-slot:item="{ item }">
                  <template v-if="item.name">
                      <div class="flex flex-row items-center">
                        <div>
                          <img
                            src="@/assets/images/files-multi.png"
                            style="width: 24px; height: 24px"
                          />
                        </div>
                        <div class="ml-2 flex flex-col justify-between">
                            <span class="font-bold text-sm text-darkPurple">
                                {{ item.data.name }}
                            </span>
                            <span class="font-normal text-blueYonder uppercase text-10">
                                {{ kbToSize(item.data.size) }}
                            </span>
                        </div>
                      </div>
                  </template>
                  <template v-else-if="item.createdAt">
                      <div class="flex flex-col">
                          <template class="text-darkPurple">
                              <div>
                                {{ $DATEFORMAT(new Date(item.data.createdAt), "MMMM dd, yyyy") }}
                              </div>
                          </template>
                          <div class="text-10 ">
                              <span class="font-normal text-blueYonder">
                                  10:00am
                              </span>
                          </div>
                      </div>
                  </template>
                  <template v-else-if="item.updatedAt">
                    <div class="flex flex-col">
                      <template class="text-darkPurple">
                          <div>
                            {{ $DATEFORMAT(new Date(item.data.updatedAt), "MMMM dd, yyyy") }}
                          </div>
                      </template>
                      <div class="text-10 ">
                          <span class="font-normal text-blueYonder">
                              10:00am
                          </span>
                      </div>
                    </div>
                  </template>
                  <span v-else-if="item.id" class="text-right">
                      <Menu left top="-150" margin="24" class="my-2 p-0">
                          <template v-slot:title>
                              <icon icon-name="more_icon" size="xs" />
                          </template>
                          <div class="w-48 flex flex-col justify-start items-center gap-3 px-3 py-2">
                              <div class="w-full" @click="actions('view-file', item.data)">
                                  <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                                      <Icon icon-name="icon-eye" class-name="text-blueCrayola" size="xs" />
                                      <p class="font-normal text-sm text-darkPurple">View File</p>
                                  </div>
                              </div>
                              <div class="w-full" @click="isUploadFile = true">
                                  <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                                      <Icon icon-name="upload-icon" class-name="text-carrotOrange" size="xs" />
                                      <p class="font-normal text-sm text-darkPurple">Upload File</p>
                                  </div>
                              </div>
                              <div class="w-full" @click="actions('download', item.data)">
                                  <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                                      <Icon icon-name="download" class-name="text-flame" size="xs" />
                                      <p class="font-normal text-sm text-darkPurple">Download File</p>
                                  </div>
                              </div>
                          </div>
                      </Menu>
                  </span>
                </template>
            </sTable>
            <div class="w-full h-64 flex flex-col justify-center items-center gap-5" v-if="files.length === 0">
                  <p class="">No files</p>
            </div>
          </div>
        </div>

        <div class="w-full h-64 m-auto text-darkPurple font-bold text-lg flex flex-col justify-center items-center" 
          v-if="currentTab === 'Identity Document'"
        >
          <p>In progress...</p>
        </div>

        <div v-if="currentTab === 'Credentials'"
          class="w-full h-64 m-auto text-darkPurple font-bold text-lg flex flex-col justify-center items-center" 
        >
          <p>In progress...</p>
        </div>

        <div v-if="currentTab === 'Medical Certificate'"
          class="w-full h-64 m-auto text-darkPurple font-bold text-lg flex flex-col justify-center items-center" 
        >
          <p>In progress...</p>
        </div>

        <div v-if="currentTab === 'Consent Documents'"
          class="w-full h-64 m-auto text-darkPurple font-bold text-lg flex flex-col justify-center items-center" 
        >
          <p>In progress...</p>
        </div>
      </div>
    </div>  

    <ViewFile v-if="openViewFile" 
      @close="openViewFile = false" 
      :item="selectedItem" 
    />
    <DownloadFile v-if="openDownloadFile" 
      @close="openDownloadFile = false" 
      :item="selectedItem" 
    />
    <UploadFile v-if="isUploadFile"
      @close="isUploadFile = false"
      :folders="folders"
      :setPermission="false"
    />
  </div>     
</template>

<script>
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import STable from "@scelloo/cloudenly-ui/src/components/table";
  
  export default {
    name: "ViewEmployee",
    components: {
      Breadcrumb,
      Tab,
      STable,
      Card: () => import('@/components/Card'),
      CardFooter: () => import("@/components/CardFooter"),
      Menu: () => import("@/components/Menu"),
      DownloadFile: () => import("@/components/documents/DownloadFile"),
      UploadFile: () => import("@/components/documents/UploadFile"),
      ViewFile: () => import("@/components/documents/ViewFile")
    },
    data() {
      return {
        isloading: false,
        currentTab: "All Files",
        owner: {},
        allFiles: [],
        allFolders: [],
        allFilesMeta: {},
        isUploadFile: false,
        openDownloadFile: false,  
        openViewFile: false,       
        selectedItem: null,
        sortType: null,
        itemsPerPage: null,
        numberOfPage: null,
        searchWord: null,
        foldersCount: 0,
        fileCount: 0,
        allFileSize: 0
      };
    },
    computed: {
      breadcrumbs() {
        return [
          {
            disabled: false,
            text: "Documents",
            href: "OrganisationCompanyDocuments",
            id: "DocumentMgt",
          },
          {
            disabled: false,
            text: "Employee Documents",
            href: "EmployeeDocuments",
            id: "EmpDocuments",
          },
          {
            disabled: false,
            text: "View Employee",
            href: "ViewEmployee",
            id: "View Employee",
          },
        ];
      },
      folders() {
        return this.allFolders;
      },
      tabs() {
        return [
          "All Files", "Identity Document",
          "Credentials", "Medical Certificate",
          "Consent Documents"
        ];
      },
      filesHeader(){ 
        return [
            { title: "File Name", value: "name" },
            { title: "First Uploaded", value: "createdAt" },
            { title: "Last Modified", value: "updatedAt" },
            { title: "", value: "id", image: true }
        ];
      },
      files(){ 
        return this.allFiles;
      },
      user(){ 
        return this.owner;
      },
      numberOfFolders(){
        return this.foldersCount;
      },
      numberOfFiles(){
        return this.fileCount;
      },
      totalSize(){
        return this.allFileSize;
      }
    },
    methods: {
      actions(type, item) {
          this.selectedItem = item;
          switch (type) {
              case 'download':
                  this.openDownloadFile = true;
              break;
              case 'view-file':
                  this.openViewFile = true;
              break;

              default:
              break;
          }
      },
      queryUpdate() {
          
      },
      handleSort(sortType) {
          this.sortType = sortType;
          this.queryUpdate();
      },
      handleItemsPerPage(itemNo) {
          this.itemsPerPage = itemNo;
          this.queryUpdate();
      },
      handlePage(pageNumber) {
          this.numberOfPage = pageNumber;
          this.queryUpdate();
      },
      handleSearch(searchWord) {
          if (searchWord) {
              this.searchWord = searchWord;
              this.queryUpdate();
          } else {
              this.searchWord = null;
              this.queryUpdate();
          }
      },
      handleItemsPerPage(itemNo) {
          this.itemsPerPage = itemNo;
          this.queryUpdate();
      },
      handlePage(pageNumber) {
          this.numberOfPage = pageNumber;
          this.queryUpdate();
      },
      async viewEmployee(){
        this.isloading = true;
        const orgId = this.$orgId;
        const userId = this.$route.params.id;
       
        try {
            const { data } = await this.$_getAnEmployees(orgId, userId);
            this.allFiles = data.files;
            this.owner = data.user;
            this.foldersCount = data.numberOfFolders;
            this.fileCount = data.numberOfFiles;
            this.allFileSize = data.totalSize === null ? this.kbToSize(0) : this.kbToSize(data.totalSize);
            this.allFilesMeta = data.meta;
            this.isloading = false;
        } catch (error) {
            this.isloading = false;
        }
      },
      async getAllEmployeesFolders(){
        const { data } = await this.$_getAllEmployeesFolders(this.$orgId);
        this.allFolders = data.folders === 0 ? [] : data.folders;
      },
      kbToSize(kb) {   
        const sizes = ['KB', 'MB', 'GB', 'TB', 'PB'];
        if (kb === 0) return '0 KB';
        if (kb < 1) return `${(kb * 1024).toFixed(1)} KB`; 
            const i = parseInt(Math.floor(Math.log(kb) / Math.log(1024)), 10);
            const sizeIndex = Math.min(i, sizes.length - 1);
        if (sizeIndex === 0) return `${kb.toFixed(1)} ${sizes[sizeIndex]}`;
        return `${(kb / (1024 ** sizeIndex)).toFixed(1)} ${sizes[sizeIndex]}`;
      },
    },
    async mounted() {
      await this.viewEmployee();
      await this.getAllEmployeesFolders()
    }
  };
</script>

<style scoped>
.card{
    width: calc(100% / 3) !important;
    height: 68px !important;
    border: 1px solid #00000029 !important;
    border-radius: 8px !important;
}
.line{
  border: 1px solid #00000029;
  width: 100%;
  height: 0;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
}
.text-10{
  font-size: 10px !important;
}
</style>
  
